import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { HttpClient, HttpHandler, HttpXhrBackend } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { metaReducersDev, metaReducersProd } from '@ap-angular/shared/ngrx';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '@ap-angular/shared/module';
import { SharedComponentModule } from '@ap-angular/shared/component';
import { ApplicationService, ServiceApplicationModule } from '@ap-angular/service/application';
import { AnalyticsService, ServiceAnalyticsModule } from '@ap-angular/service/analytics';
import { AppointmentService, ServiceAppointmentModule } from '@ap-angular/service/appointment';
import { ContentManagementService, ServiceContentManagementModule } from '@ap-angular/service/content-management';
import { DialogService, ServiceDialogModule } from '@ap-angular/service/dialog';
import { HttpAuthInterceptor, HttpEndpointsModule, httpServiceFactory, RequestOptions } from '@ap-angular/service/http';
import { MessageService, ServiceMessageModule } from '@ap-angular/service/message';
import { MediaService, ServiceMediaModule } from '@ap-angular/service/media';
import { ProviderService, ServiceProviderModule } from '@ap-angular/service/provider';
import { ServiceUserModule, UserService } from '@ap-angular/service/user';
import { HttpLoaderFactory, InitTranslateFactory, DEFAULT_LANGUAGE } from '@ap-angular/service/translate';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { DATEPICKER_FORMATS } from '@ap-angular/shared/schema';

const metaReducers: Array<MetaReducer<any, any>> = environment.production ? metaReducersProd : metaReducersDev;

const devModule: Array<any> = environment.production ? [] : [StoreDevtoolsModule.instrument({
	maxAge: 25, // Retains last 25 states
})];

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		TranslateModule.forRoot({
			defaultLanguage: DEFAULT_LANGUAGE,
			loader: {
				provide: TranslateLoader,
				useFactory: (HttpLoaderFactory),
				deps: [HttpClient]
			},
			isolate: false,
			extend: true
		}),
		StoreModule.forRoot({}, {
			metaReducers,
			runtimeChecks: {
				strictStateImmutability: true,
				strictActionImmutability: true,
				strictStateSerializability: false,
				strictActionSerializability: false,
				strictActionWithinNgZone: true,
				strictActionTypeUniqueness: true
			}
		}),
		EffectsModule.forRoot([]),
		...devModule,

		BrowserModule,

		AppRoutingModule,

		BrowserModule,
		BrowserAnimationsModule,

		HttpEndpointsModule, // Root!!!

		SharedModule,
		SharedComponentModule,

		ServiceAnalyticsModule,
		ServiceApplicationModule,
		ServiceAppointmentModule,
		ServiceContentManagementModule,
		ServiceDialogModule,
		ServiceMediaModule,
		ServiceMessageModule,
		ServiceProviderModule,
		ServiceUserModule,

		MatDatepickerModule,
		MomentDateModule
	],
	exports: [
		TranslateModule
	],
	providers: [
		{ provide: 'ENVIRONMENT', useValue: environment },
		{ provide: MAT_DATE_FORMATS, useValue: DATEPICKER_FORMATS },
		{
			provide: APP_INITIALIZER,
			useFactory: InitTranslateFactory,
			deps: [TranslateService, Injector],
			multi: true
		},
		{
			provide: HttpClient,
			useFactory: httpServiceFactory,
			deps: [HttpXhrBackend, HttpAuthInterceptor, RequestOptions, HttpHandler]
		},

		AnalyticsService,
		ApplicationService,
		AppointmentService,
		ContentManagementService,
		DialogService,
		MediaService,
		MessageService,
		ProviderService,
		UserService,
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
